import AppIcon from 'src/components/common/app-icon';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import stopPropagation from 'src/utils/stop-propagation';
import { Checkbox } from 'antd';
import { DEFAULT_CELL_OVERFLOW_STYLE } from '../cell-overflow-styles';
import { cellClsPrefix, headerCellClsPrefix } from '../cls-prefixes';
import { observer } from 'mobx-react';
import SortUpIcon from '!!svgJsx!src/images/icons/triangle-up.svg';
import SortDownIcon from '!!svgJsx!src/images/icons/triangle-down.svg';
import SortNoneIcon from '!!svgJsx!src/images/icons/triangle-up-and-down.svg';

const SelectionRowHeaderContent = observer(
  ({ someSelected, allSelected, selectAll, deselectAll }) => (
    <Checkbox
      checked={!!allSelected}
      indeterminate={!allSelected && !!someSelected}
      onClick={stopPropagation(!allSelected ? selectAll : deselectAll)}
    />
  )
);

const SortControls = ({ value, iconSize }) => (
  <div className={`${headerCellClsPrefix}__sort-controls`}>
    <AppIcon
      className={classNames(`${headerCellClsPrefix}__sort-icon`, {
        // eslint-disable
        [`${headerCellClsPrefix}__sort-icon-asc`]:
          value && value.order === 'asc',
        [`${headerCellClsPrefix}__sort-icon-desc`]:
          value && value.order === 'desc',
        [`${headerCellClsPrefix}__sort-icon-none`]: !value,
      })}
      type="svg"
      size={iconSize}
      svg={
        value
          ? // eslint-disable
            value.order === 'asc'
            ? SortUpIcon
            : SortDownIcon
          : SortNoneIcon
      }
    />
  </div>
);
SortControls.propTypes = {
  value: PropTypes.object,
  iconSize: PropTypes.number,
};
SortControls.defaultProps = {
  iconSize: 16,
};

export default observer(
  ({
    overflowStyle = DEFAULT_CELL_OVERFLOW_STYLE,
    className,
    selection,
    selectionHeaderProps,
    sortable,
    sortValue,
    setSort,
    onClick,
    ...remainingProps
  }) => (
    <th
      className={classNames(
        cellClsPrefix,
        headerCellClsPrefix,
        `${cellClsPrefix}--${overflowStyle}`,
        className,
        {
          [`${headerCellClsPrefix}--selection`]: selection,
          [`${headerCellClsPrefix}--sortable`]: sortable,
        }
      )}
      {...remainingProps}
      onClick={(e, ...args) => {
        if (sortable) {
          setSort(Boolean(e.ctrlKey || e.shiftKey));
        }
        if (onClick) {
          onClick(e, ...args);
        }
      }}
    >
      <div className={`${headerCellClsPrefix}--sortable-content`}>
        {selectionHeaderProps ? (
          <SelectionRowHeaderContent {...selectionHeaderProps} />
        ) : (
          remainingProps.children
        )}
        {sortable && <SortControls value={sortValue} />}
      </div>
    </th>
  )
);
