

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';

const clsPrefix = 'app-title-wrap-cell';

export default function TitleWrap({
  className,
  // TODO: Refactor to take `maxLines` and `lineHeight` instead of just a `height`
  height = '45px',
  description,
  children,
}) {
  return (
    <div
      className={classNames(clsPrefix, className)}
      style={{
        height,
      }}
    >
      <div
        className={classNames(`${clsPrefix}__title-wrap-content`, {
          [`${className}__title-wrap-content`]: !!className,
        })}
      >
        {description ? (
          <Tooltip title={description}>
            <span
              className={classNames(`${clsPrefix}__tooltip-underline`, {
                [`${className}__tooltip-underline`]: !!className,
              })}
            >
              {children}
            </span>
          </Tooltip>
        ) : (
          children
        )}
      </div>
    </div>
  );
}

TitleWrap.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any,
};
