

import React from 'react';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import AddressLabel from 'src/components/common/address-label';
import DatePopover from 'src/components/common/date-popover';
import OverflowLabel from 'src/components/common/overflow-label';
import { DATE_FORMAT, DEFAULT_GUTTER } from 'src/constants';
import formatTimestamp from 'src/utils/format-timestamp';
import getFullName from 'src/utils/get-full-name';
import isEmpty from 'src/utils/is-empty';

const clsPrefix = 'app-label-value-table';

const LABEL_COL_LAYOUT = {
  span: 8,
};

const VALUE_COL_LAYOUT = {
  span: 16,
};

const VALUE_COL_NON_LABEL_LAYOUT = {
  span: 24,
};

const Empty = () => <span className={`${clsPrefix}__empty`} />;

function LabelValueRow({
  id,
  className,
  label,
  ellipsisOverflow,
  children,
  extra,
  gutter = DEFAULT_GUTTER,
  labelCol = {
    ...LABEL_COL_LAYOUT,
  },
  valueCol = {
    ...(label ? VALUE_COL_LAYOUT : VALUE_COL_NON_LABEL_LAYOUT),
  },
  ...rowProps
}) {
  return (
    <Row
      id={id}
      className={classNames(`${clsPrefix}__row`, className)}
      gutter={gutter}
      {...rowProps}
    >
      {label && (
        <Col className={`${clsPrefix}__label-col`} {...labelCol}>
          {React.isValidElement(label) ? (
            label
          ) : (
            <OverflowLabel
              tooltip
              ellipsisRight
              overflow={!!ellipsisOverflow}
              className={classNames(`${clsPrefix}__label`, {
                [`${clsPrefix}__label-col--wrap`]: !ellipsisOverflow,
              })}
            >
              {label}
            </OverflowLabel>
          )}
        </Col>
      )}
      <Col className={`${clsPrefix}__value-col`} {...valueCol}>
        {children}
        {extra && <div className={`${clsPrefix}__extra`}>{extra}</div>}
      </Col>
    </Row>
  );
}

function ValueCell({ children, emptyPlaceholder = true }) {
  return (
    <div className={`${clsPrefix}__value`}>
      {isEmpty(children) && emptyPlaceholder ? <Empty /> : children}
    </div>
  );
}

ValueCell.propTypes = {
  children: PropTypes.any,
  emptyPlaceholder: PropTypes.bool,
};

LabelValueRow.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.any,
  children: PropTypes.any,
  emptyPlaceholder: PropTypes.bool,
  gutter: PropTypes.number,
  labelCol: PropTypes.object,
  valueCol: PropTypes.object,
  extra: PropTypes.any,
  ellipsisOverflow: PropTypes.bool,
};

function rowComponent(ValueComponent) {
  function RowComponent({ value, ...props }) {
    const rowPropKeys = [
      'id',
      'label',
      'children',
      'gutter',
      'labelCol',
      'valueCol',
      'extra',
      'ellipsisOverflow',
      'onMouseEnter',
      'onMouseLeave',
    ];
    const rowProps = pick(props, rowPropKeys);
    const valueProps = omit(props, rowPropKeys);
    return (
      <LabelValueRow {...rowProps}>
        <ValueComponent value={value} {...valueProps} />
      </LabelValueRow>
    );
  }
  RowComponent.propTypes = {
    value: PropTypes.any,
  };

  return RowComponent;
}

function AddressValue({ value, emptyPlaceholder }) {
  return (
    <ValueCell emptyPlaceholder={emptyPlaceholder}>
      {value ? <AddressLabel address={value} /> : null}
    </ValueCell>
  );
}

const AddressRow = rowComponent(AddressValue);

function StringValue({ value, prefix, emptyPlaceholder }) {
  return (
    <ValueCell emptyPlaceholder={emptyPlaceholder}>
      {value ? (
        <span>
          {prefix}
          {value}
        </span>
      ) : null}
    </ValueCell>
  );
}

StringValue.propTypes = {
  value: PropTypes.any,
  emptyPlaceholder: PropTypes.bool,
  prefix: PropTypes.string,
};

const StringRow = rowComponent(StringValue);

AddressValue.propTypes = {
  value: PropTypes.object,
  emptyPlaceholder: PropTypes.bool,
};

function NumberValue({
  value,
  emptyIfZero = false,
  precision = 0,
  locale = 'en-US',
  formatOptions = {},
  emptyPlaceholder,
}) {
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: precision,
    ...formatOptions,
  });
  return (
    <ValueCell emptyPlaceholder={emptyPlaceholder}>
      {isEmpty(value) || (value === 0 && emptyIfZero)
        ? null
        : formatter.format(value)}
    </ValueCell>
  );
}

const NumberRow = rowComponent(NumberValue);

NumberValue.propTypes = {
  value: PropTypes.any,
  emptyIfZero: PropTypes.bool,
  precision: PropTypes.number,
  locale: PropTypes.string,
  formatOptions: PropTypes.object,
  emptyPlaceholder: PropTypes.bool,
};

function CurrencyValue({ formatOptions, currency = 'USD', ...props }) {
  return (
    <NumberValue
      formatOptions={({
        style: 'currency',
          currency,
        ...formatOptions
      })}
      {...props}
    />
  );
}

CurrencyValue.propTypes = {
  formatOptions: PropTypes.object,
  currency: PropTypes.string,
};

const CurrencyRow = rowComponent(CurrencyValue);

const DateValueInner = ({ value, format = DATE_FORMAT }) => {
  return <DatePopover value={value}>{value.format(format)}</DatePopover>;
};

DateValueInner.propTypes = {
  value: PropTypes.object.isRequired,
  format: PropTypes.string,
};

const DateValue = ({ value, format = DATE_FORMAT, emptyPlaceholder }) => {
  return (
    <ValueCell emptyPlaceholder={emptyPlaceholder}>
      {!isEmpty(value) ? (
        <DateValueInner value={moment(value, DATE_FORMAT)} format={format} />
      ) : null}
    </ValueCell>
  );
};

DateValue.propTypes = {
  value: PropTypes.object.isRequired,
  format: PropTypes.string,
  emptyPlaceholder: PropTypes.bool,
};

const DateRow = rowComponent(DateValue);

const TimestampValue = ({ value, ...props }) => (
  <ValueCell {...props}>
    {!isEmpty(value) ? formatTimestamp(value) : null}
  </ValueCell>
);

TimestampValue.propTypes = {
  value: PropTypes.any,
};

const TimestampRow = rowComponent(TimestampValue);

const ContactValue = ({ value, ...props }) => (
  <ValueCell {...props}>
    {!isEmpty(value) ? (
      <React.Fragment>{getFullName(value)}</React.Fragment>
    ) : null}
  </ValueCell>
);

ContactValue.propTypes = {
  value: PropTypes.object,
};

const ContactRow = rowComponent(ContactValue);

export default function LabelValueTable({
  className,
  children,
  withBorders,
  boldLabels,
  mode,
  ...otherProps
}) {
  return (
    <div
      className={classNames(clsPrefix, className, {
        [`${clsPrefix}--with-borders`]: !!withBorders,
        [`${clsPrefix}--bold-labels`]: !!boldLabels,
        [`${clsPrefix}--horizontal`]: !mode || mode === 'horizontal',
        [`${clsPrefix}--vertical`]: mode === 'vertical',
      })}
      {...otherProps}
    >
      {children}
    </div>
  );
}

LabelValueTable.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  withBorders: PropTypes.bool,
  boldLabels: PropTypes.bool,
  mode: PropTypes.oneOf(['horizontal', 'vertical']),
};
LabelValueTable.defaultProps = {
  withBorders: false,
  boldLabels: false,
  mode: 'horizontal',
};

LabelValueTable.Row = observer(LabelValueRow);
LabelValueTable.ValueCell = observer(ValueCell);
LabelValueTable.AddressRow = observer(AddressRow);
LabelValueTable.ContactRow = observer(ContactRow);
LabelValueTable.NumberRow = observer(NumberRow);
LabelValueTable.CurrencyRow = observer(CurrencyRow);
LabelValueTable.DateRow = observer(DateRow);
LabelValueTable.TimestampRow = observer(TimestampRow);
LabelValueTable.StringRow = observer(StringRow);
LabelValueTable.AddressValue = observer(AddressValue);
LabelValueTable.NumberValue = observer(NumberValue);
LabelValueTable.CurrencyValue = observer(CurrencyValue);
LabelValueTable.DateValue = observer(DateValue);
LabelValueTable.StringValue = observer(StringValue);
LabelValueTable.Empty = Empty;
