import moment, { type Moment } from 'moment';

// Case-insensitive; accented characters are considered different;
// "10" comes after "2"; ignore punctuation.
export const stringSorter = (str1: string, str2: string) => {
  return (str1 || '').localeCompare(str2 || '', undefined, {
    numeric: true,
    sensitivity: 'accent',
    ignorePunctuation: true,
  });
};

export const numberSorter = (n1: string, n2: string) =>
  parseFloat(n1) > parseFloat(n2) ? 1 : -1;

export const momentSorter = (m1: Moment, m2: Moment) =>
  moment(m1).isAfter(moment(m2)) ? 1 : -1;

export default {
  string: stringSorter,
  number: numberSorter,
  moment: momentSorter,
};
