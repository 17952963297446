import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import withRateLimit from 'src/utils/with-rate-limit';
import { appTableClsPrefix, bodyRowClsPrefix } from '../cls-prefixes';
import { RowContext } from './context';

const _FULL_HEIGHT_CONTAINER_CLASS = `${appTableClsPrefix}__body-cell--full-height`;

class InnerTr extends Component {
  static propTypes = {
    className: PropTypes.string,
    provided: PropTypes.object,
    isDraggable: PropTypes.bool,
    isDroppable: PropTypes.bool,
    isGhostRow: PropTypes.bool,
    children: PropTypes.any,
    height: PropTypes.any,
    trKey: PropTypes.string,
    droppablePlaceholder: PropTypes.any,
    reRender: PropTypes.func,
  };

  render() {
    const {
      className,
      provided,
      isDraggable,
      isDroppable,
      isGhostRow,
      children,
      height,
      trKey,
      droppablePlaceholder,
      reRender,
      ...props
    } = this.props;

    if (droppablePlaceholder) {
      return droppablePlaceholder;
    }

    const draggableProps = provided
      ? {
          ref: provided.innerRef,
          ...provided.draggableProps,
          ...provided.dragHandleProps,
        }
      : {};

    return (
      <tr
        key={trKey}
        className={classNames(bodyRowClsPrefix, className, {
          [`${appTableClsPrefix}__body-row--draggable`]: isDraggable,
          [`${appTableClsPrefix}__body-row--droppable`]: isDroppable,
          [`${appTableClsPrefix}__body-row--ghost-row`]: isGhostRow,
        })}
        {...props}
        {...draggableProps}
      >
        <RowContext.Provider
          value={{
            rowHeight: height,
            reRenderRow: reRender,
          }}
        >
          {children}
        </RowContext.Provider>
      </tr>
    );
  }
}

export default class DefaultTr extends Component {
  static propTypes = {
    isDraggable: PropTypes.bool,
    isDroppable: PropTypes.bool,
    isGhostRow: PropTypes.bool,
    children: PropTypes.any,
    rk: PropTypes.any,
    rowIndex: PropTypes.number,
    rowDraggableIndex: PropTypes.number,
    rowDroppableIndex: PropTypes.number,
    droppablePlaceholder: PropTypes.any,
  };
  state = {
    height: undefined,
    trKey: Math.random().toString(),
  };

  componentDidMount() {
    this._unmounted = false;
    this.updateHeight();
    window.addEventListener('resize', this.updateHeightDebounced);
  }

  componentDidUpdate() {
    this.updateHeight();
  }

  componentWillUnmount() {
    this._unmounted = true;
    window.removeEventListener('resize', this.updateHeightDebounced);
  }

  getNewHeight = withRateLimit((newHeight) => {
    return {
      height: newHeight,
    };
  });

  updateHeight = () => {
    // DOM measurements on row DOM Element. Seems to be the only way to control <tr/>'s
    //  children height if that depends on overall row height. Used only for child <td/>s
    //  where isFullHeight == true
    if (!this._unmounted) {
      const DOMElement = ReactDOM.findDOMNode(this); // eslint-disable-line react/no-find-dom-node
      if (DOMElement) {
        const height = Array.from(DOMElement.querySelectorAll('td'))
          .filter((td) => !td.classList.contains(_FULL_HEIGHT_CONTAINER_CLASS))
          .map((td) => td.clientHeight)
          .reduce((max, h) => Math.max(max, h), 0);
        if (height) {
          this.setState((prevState) => {
            if (Math.abs(height - (prevState.height || 0)) > 0.5) {
              return this.getNewHeight(height) || null;
            }

            return null;
          });
        }
      }
    }
  };
  updateHeightDebounced = debounce(this.updateHeight, 250);

  reRender = () =>
    this.setState({
      trKey: Math.random().toString(),
    });

  render() {
    const {
      rk,
      rowDraggableIndex,
      rowDroppableIndex,
      rowIndex: unusedRowIndex,
      isDraggable,
      isDroppable,
      ...remainingProps
    } = this.props;

    const innerTrProps = {
      isDraggable,
      isDroppable,
      reRender: this.reRender,
      height: this.state.height,
      trKey: this.state.trKey,
    };

    if (isDraggable) {
      return (
        <Draggable key={rk} draggableId={rk} index={rowDraggableIndex}>
          {(provided) => (
            <InnerTr
              {...remainingProps}
              {...innerTrProps}
              provided={provided}
            />
          )}
        </Draggable>
      );
    } if (isDroppable) {
      return (
        <Droppable key={rk} droppableId={rk} index={rowDroppableIndex}>
          {(provided) => (
            <InnerTr
              {...remainingProps}
              {...innerTrProps}
              provided={provided}
            />
          )}
        </Droppable>
      );
    }

    return <InnerTr {...remainingProps} {...innerTrProps} />;
  }
}
