export const sizes = {
  xsmall: 'xsmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge',
};

export const sizeHPadding = {
  [sizes.xsmall]: 4,
  [sizes.small]: 8,
  [sizes.medium]: 8,
  [sizes.large]: 12,
  [sizes.xlarge]: 16,
};

export const tableHPadding = 28;
export const tableHPaddingXs = 16;
