import React from 'react';
import classNames from 'classnames';
import { Resizable } from 'react-resizable';

const clsPrefix = 'resizable-wrapper';

// HOC to make a component resizable with 'react-resizable'. If custom header cell is
//  provided with resizableColumn option set to true, such component should be wrapped
//  with this
export default (WrappedComp, resizableWrapperClassName) => ({
  onResize, // eslint-disable-line react/prop-types
  width, // eslint-disable-line react/prop-types
  ...remainingProps
}) => {
  const wrappedComp = <WrappedComp {...remainingProps} />;

  return width ? (
    <Resizable
      className={classNames(clsPrefix, resizableWrapperClassName)}
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false,
      }}
    >
      {wrappedComp}
    </Resizable>
  ) : (
    wrappedComp
  );
};
