import AppIcon from 'src/components/common/app-icon';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import dragIcon from '!!svgJsx!src/images/documents/drag-new.svg';

const iconStyle = {
  backgroundColor: 'transparent',
  color: '#999',
  border: 0,
  fontWeight: 'bold',
  marginRight: '-10px',
};

const Ellipsis = ({ disabled, size, type }) => {
  const sizeProp = size || 'default';
  const iconSize = sizeProp === 'default' ? null : 20;
  if (type === 'svg') {
    return (
      <AppIcon
        type="svg"
        name="more"
        svg={dragIcon}
        size={16}
        style={{
          cursor: !disabled ? 'move' : 'not-allowed',
        }}
      />
    );
  }
  return (
    <AppIcon
      type="antd"
      name="more"
      size={iconSize}
      style={{
        ...iconStyle,
        ...(sizeProp === 'default'
          ? {}
          : {
              marginRight: '-14px',
            }),
        cursor: !disabled ? 'move' : 'not-allowed',
      }}
    />
  );
};
Ellipsis.propTypes = {
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'large']),
};

const VerticalGrip = ({
  className,
  dragHandleProps,
  style,
  disabled,
  size,
  type,
}) => (
  <div
    className={classNames('app-vertical-grip', className)}
    style={{
      minWidth: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...style,
    }}
    {...dragHandleProps}
  >
    {type === 'svg' ? (
      <Ellipsis disabled={disabled} size={size} type={type} />
    ) : (
      <span>
        <Ellipsis disabled={disabled} size={size} type={type} />
        <Ellipsis disabled={disabled} size={size} type={type} />
      </span>
    )}
  </div>
);

VerticalGrip.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  dragHandleProps: PropTypes.object,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'large']),
};

export default VerticalGrip;
