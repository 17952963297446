

import React, { Component, Fragment } from 'react';
import { Input, Tooltip } from 'antd';
import classNames from 'classnames';
import isString from 'lodash/isString';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import AppButton from 'src/components/common/app-button';
import AppIcon from 'src/components/common/app-icon';
import OverflowLabel from 'src/components/common/overflow-label';
import stopPropagation from 'src/utils/stop-propagation';

const clsPrefix = 'app-title-subtitle-cell';

@inject('ui')
@observer
export default class TitleSubtitleCell extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    className: PropTypes.string,
    content: PropTypes.object,
    title: PropTypes.string.isRequired,
    wrappedTitle: PropTypes.object,
    titlePrefix: PropTypes.any,
    titleOverflowLabel: PropTypes.bool,
    subtitle: PropTypes.any,
    subtitleOnRowHoverOnly: PropTypes.bool,
    renameTitle: PropTypes.func,
    isRenaming: PropTypes.bool,
    onSave: PropTypes.func,
    onClick: PropTypes.func,
    children: PropTypes.any,
    bold: PropTypes.bool,
    thin: PropTypes.bool,
    tooltip: PropTypes.any,
    tooltipProps: PropTypes.object,
  };

  isInputFocusing = false;

  constructor(props) {
    super(props);
    this.state = {
      editTitle: props.title || '',
      isRenaming: props.isRenaming,
    };
  }

  componentDidUpdate(prevProps) {
    this.setEditTitleWhenRenamingIsSet(prevProps);
    this.saveEditingTitleWhenRenamingIsSet(prevProps);
  }

  setEditTitleWhenRenamingIsSet(prevProps) {
    if (this.props.isRenaming && !prevProps.isRenaming) {
      this.setState({
        editTitle: this.props.title || '',
      });
    }
  }

  async saveEditingTitleWhenRenamingIsSet(prevProps) {
    if (this.props.isRenaming !== prevProps.isRenaming) {
      if (!this.props.isRenaming && this.isInputFocusing) {
        await this.save();
      }
      this.setState({
        isRenaming: this.props.isRenaming,
      });
    }
  }

  save = async () => {
    const { onSave, ui } = this.props;
    const { editTitle } = this.state;
    this.setState({
      isSaving: true,
    });
    try {
      await onSave(editTitle || '');
    } catch (err) {
      ui.wentWrong(err);
    } finally {
      this.setState({
        isSaving: false,
      });
    }
  };

  cancel = () => {
    const { title } = this.props;
    this.setState(
      {
        editTitle: title,
      },
      () => this.save()
    );
  };

  get leftContent() {
    return (this.props.content || {}).left;
  }

  get rightContent() {
    return (this.props.content || {}).right;
  }

  get endContent() {
    return (this.props.content || {}).end;
  }

  render() {
    const {
      className,
      title,
      bold,
      thin,
      titlePrefix,
      titleOverflowLabel,
      subtitle,
      subtitleOnRowHoverOnly,
      renameTitle,
      onClick,
      tooltip,
      tooltipProps,
      wrappedTitle,
      children,
    } = this.props;

    const { editTitle, isSaving, isRenaming } = this.state;

    const multiLineSubtitle =
      !!subtitle && isString(subtitle) && subtitle.split('\n').length > 1;

    const TitleComponent = titleOverflowLabel
      ? OverflowLabel
      : ({ ...props }) => <span {...props} />;
    const titleProps = titleOverflowLabel
      ? {
          tooltip: true,
        }
      : {};

    const content = (
      <div
        className={classNames(clsPrefix, className, {
          [`${clsPrefix}--subtitle-always`]:
            Boolean(subtitle) && !subtitleOnRowHoverOnly,
          [`${clsPrefix}--subtitle-on-row-hover-only`]:
            Boolean(subtitle) && !!subtitleOnRowHoverOnly,
          [`${clsPrefix}--no-subtitle`]: !subtitle,
          [`${clsPrefix}--thin`]: Boolean(thin),
        })}
        role="button"
        onClick={onClick}
      >
        {!isRenaming ? (
          <Fragment>
            {!!this.leftContent && (
              <div
                className={classNames(`${clsPrefix}__left-content`, {
                  [`${clsPrefix}__left-content--with-multi-line-subtitle`]:
                    multiLineSubtitle,
                })}
              >
                {this.leftContent}
              </div>
            )}
            <div
              className={classNames(`${clsPrefix}__typographies`, {
                [`${clsPrefix}__typographies--with-multi-line-subtitle`]:
                  multiLineSubtitle,
              })}
            >
              <div className={`${clsPrefix}__title-container`}>
                {!!titlePrefix && (
                  <div
                    className={classNames(`${clsPrefix}__title-prefix`, {
                      [`${clsPrefix}__title-prefix--no-subtitle`]: !subtitle,
                    })}
                  >
                    {titlePrefix}
                  </div>
                )}
                <TitleComponent
                  className={classNames(
                    `${clsPrefix}__typography`,
                    `${clsPrefix}__title`,
                    {
                      [`${clsPrefix}__title--no-subtitle`]: !subtitle,
                      [`${clsPrefix}__title--bold`]: !!bold,
                    }
                  )}
                  {...titleProps}
                >
                  {wrappedTitle || title || 'Untitled'}
                </TitleComponent>
                {!!renameTitle && (
                  <AppButton
                    className={`${clsPrefix}__rename`}
                    onClick={stopPropagation(renameTitle)}
                  >
                    <AppIcon name="edit3" size={14} />
                  </AppButton>
                )}
              </div>
              {!!subtitle && isString(subtitle)
                ? subtitle.split('\n', 2).map((st) => (
                    <span
                      key={st}
                      className={classNames(
                        `${clsPrefix}__typography`,
                        `${clsPrefix}__subtitle`,
                        {
                          [`${clsPrefix}__subtitle--multi-line`]:
                            multiLineSubtitle,
                        }
                      )}
                    >
                      {st}
                    </span>
                  ))
                : subtitle}
            </div>
            {children}
            {!!this.rightContent && (
              <div
                className={classNames(`${clsPrefix}__right-content`, {
                  [`${clsPrefix}__right-content--with-multi-line-subtitle`]:
                    multiLineSubtitle,
                })}
              >
                {this.rightContent}
              </div>
            )}
            {!!this.endContent && (
              <div className={`${clsPrefix}__end-content`}>
                {this.endContent}
              </div>
            )}
          </Fragment>
        ) : (
          <Input
            className={`${clsPrefix}__rename-input`}
            autoFocus
            placeholder="Untitled"
            value={editTitle}
            disabled={isSaving}
            onChange={(event) =>
              this.setState({
                editTitle: event.target.value || '',
              })
            }
            onFocus={(event) => {
              event.target.select();
              this.isInputFocusing = true;
            }}
            onClick={stopPropagation(() => null)}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                this.save();
              } else if (event.key === 'Escape') {
                this.cancel();
              }
            }}
            onBlur={() => {
              this.save();
              this.isInputFocusing = false;
            }}
          />
        )}
      </div>
    );

    return tooltip && !isRenaming ? (
      <Tooltip {...tooltipProps} title={tooltip}>
        {content}
      </Tooltip>
    ) : (
      content
    );
  }
}
