import ActionsCell from './actions-cell';
import AddressCell from './address-cell';
import SelectionCell from './selection-cell';
import TitleSubtitleCell from './title-subtitle-cell';
import TitleWrapCell from './title-wrap-cell';
import TsCell from './ts-cell';

const CELLS = {
  Actions: ActionsCell,
  Address: AddressCell,
  Selection: SelectionCell,
  TitleSubtitle: TitleSubtitleCell,
  TitleWrap: TitleWrapCell,
  Ts: TsCell,
} as const;

export default CELLS;
