import React, { Component } from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';

// WARNING: usage inside a draggable element causes performance issues.
export default class OverflowLabel extends Component {
  static propTypes = {
    overflow: PropTypes.bool,
    tailLength: PropTypes.number,
    className: PropTypes.string,
    tooltip: PropTypes.bool,
    tooltipProps: PropTypes.object,
    children: PropTypes.any,
    ellipsisRight: PropTypes.bool,
  };

  static defaultProps = {
    overflow: true,
    ellipsisRight: false,
    tailLength: 8,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      hasTail: false,
    };
  }

  setEl = (el) => {
    this.el = el;
  };

  adjustTail = () => {
    if (!this.el) {
      return;
    }
    const { overflow, tailLength } = this.props;

    const el = this.el;
    const tail =
      overflow && tailLength && el.offsetWidth < el.scrollWidth
        ? el.textContent.slice(-tailLength)
        : '';

    el.dataset.tail = tail;
    this.setState({
      hasTail: !!tail,
    });
  };

  componentDidMount() {
    setTimeout(() => {
      this.adjustTail();
    }, 0);
    const { overflow } = this.props;
    if (overflow) {
      window.addEventListener('resize', this.adjustTail);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.adjustTail);
  }

  render() {
    const { overflow, className, ellipsisRight, tooltipProps } = this.props;
    const label = (
      <div
        ref={this.setEl}
        className={classNames(className, {
          'ellipsis-overflow-tail': overflow && !ellipsisRight,
          'ellipsis-overflow': overflow && ellipsisRight,
        })}
        {...omit(this.props, [
          'overflow',
          'tailLength',
          'className',
          'tooltip',
          'ellipsisRight',
        ])}
      />
    );
    return this.props.tooltip && overflow && this.state.hasTail ? (
      <Tooltip title={this.props.children} {...tooltipProps}>
        {label}
      </Tooltip>
    ) : (
      label
    );
  }
}
