// Block a function form being called more than {maxCalls} within a timeWindow of {timeWindow}.
// Can be used as quick & dirty approach to break infinitely recursive calls in uncertain
// scenarios.
const withRateLimit = (
  fn: (...args: unknown[]) => unknown,
  { timeWindow = 500, maxCalls = 10, logError = true } = {}
) => {
  let callsCount = 0;
  return (...args: unknown[]) => {
    if (callsCount < maxCalls) {
      callsCount += 1;
      setTimeout(() => {
        callsCount -= 1;
      }, timeWindow);
      return fn(...args);
    }
    const msg = `Function was called more than ${maxCalls} times within a ${timeWindow}ms time window, and was prevented from being called again.`;
    console.error(msg);
    if (logError) {
      window.DD_RUM?.addError(new Error(msg));
    }

    return undefined;
  };
};
export default withRateLimit;
