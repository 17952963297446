

import React, { Component } from 'react';
import { Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import { PropTypes } from 'prop-types';
import AppIconButton from 'src/components/common/app-icon-button';
import stopPropagation from 'src/utils/stop-propagation';

const clsPrefix = 'app-actions-cell';

const { Item, SubMenu } = Menu;

export default class ActionsCell extends Component {
  static propTypes = {
    className: PropTypes.string,
    actions: PropTypes.array,
    visible: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    button: PropTypes.any,
    onVisibleChange: PropTypes.func,
    dropdownButtonProps: PropTypes.object,
    dropdownProps: PropTypes.object,
    visibleOnRowHover: PropTypes.bool,
  };

  static defaultProps = {
    visibleOnRowHover: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: !!props.visible,
    };
  }

  get actions() {
    return this.props.actions.filter((a) => a);
  }

  get actionsByKey() {
    return keyBy(
      flatMap(this.actions, (value) => {
        return value.children ? [value, ...value.children] : [value];
      }),
      'key'
    );
  }

  get visibilityControlled() {
    return this.props.visible !== undefined;
  }

  get visible() {
    if (this.visibilityControlled) {
      return !!this.props.visible;
    }
    return !!this.state.visible;
  }

  onVisibleChange = async (visible) => {
    const { onVisibleChange } = this.props;
    if (onVisibleChange) {
      await onVisibleChange(visible);
    }
    this.setState({
      visible,
    });
  };

  onMenuClick = stopPropagation(({ key, domEvent }) => {
    const { actionsByKey } = this;
    if (get(actionsByKey[key], 'onClick')) {
      actionsByKey[key].onClick(domEvent);
    }
    this.onVisibleChange(false);
  });

  renderActionItem = ({
    children,
    key,
    label,
    disabled: actionDisabled,
    onClick: _onClick,
    ...otherProps
  }) => {
    if (children) {
      return (
        <SubMenu
          title={label}
          key={key}
          disabled={!!actionDisabled}
          {...otherProps}
        >
          {children.map(this.renderActionItem)}
        </SubMenu>
      );
    }
    return (
      <Item key={key} disabled={!!actionDisabled} {...otherProps}>
        {label}
      </Item>
    );
  };

  render() {
    const {
      className,
      button,
      dropdownProps,
      dropdownButtonProps,
      loading,
      disabled,
      visibleOnRowHover,
    } = this.props;

    return (
      <div
        className={classNames(clsPrefix, className, {
          [`${clsPrefix}--visible-on-row-hover`]: !!visibleOnRowHover,
          [`${clsPrefix}--active`]: this.visible,
        })}
      >
        <div className={`${clsPrefix}__inner`}>
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu className={`${clsPrefix}__menu`} onClick={this.onMenuClick}>
                {this.actions.map(this.renderActionItem)}
              </Menu>
            }
            visible={this.visible}
            onVisibleChange={(visible) => this.onVisibleChange(visible)}
            onClick={stopPropagation}
            {...(dropdownProps || {})}
          >
            {button || (
              <AppIconButton
                className={`${clsPrefix}__dd-btn`}
                disabled={disabled}
                {...(dropdownButtonProps || {})}
                iconSize={14}
                iconType="feather"
                name="moreVertical"
                loading={!!loading}
              />
            )}
          </Dropdown>
        </div>
      </div>
    );
  }
}
