

import React, { Component } from 'react';
import classNames from 'classnames';
import stopPropagation from 'src/utils/stop-propagation';
import { Checkbox } from 'antd';
import { PropTypes } from 'prop-types';

const clsPrefix = 'app-selection-cell';

export const SELECTION_WIDTH = 50;

export default class SelectionCell extends Component {
  static propTypes = {
    className: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    checkboxProps: PropTypes.object,
  };

  render() {
    const {
      className,
      checked,
      onChange,
      disabled,
      hidden,
      checkboxProps,
      ...otherProps
    } = this.props;

    return (
      <div
        className={classNames(clsPrefix, className)}
        onClick={stopPropagation(() => onChange(!checked))}
        role="button"
        {...otherProps}
      >
        {!hidden && (
          <Checkbox
            className={`${clsPrefix}__checkbox`}
            checked={!!checked}
            onClick={stopPropagation((event) => {
              const isShiftPressed = event.shiftKey;
              return onChange(!checked, isShiftPressed);
            })}
            {...checkboxProps}
            disabled={
              disabled !== undefined
                ? !!disabled
                : !!(checkboxProps || {}).disabled
            }
          />
        )}
      </div>
    );
  }
}
