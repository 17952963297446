import React, {
  Children,
  cloneElement,
  ComponentType,
  FC,
  ReactNode,
  ReactElement,
} from 'react';
import isFunction from 'lodash/isFunction';

/*
  returns a list of Children React comps with added props. addProps can be a fixed object
  containing the props to add to children, or it can be a function
  (child, index) => ({...}), where child is the original child component, and index the
  index of the position of that child inside its parent (0-based idx)
*/
type AddPropsOfAugmentChildren =
  | Record<any, any>
  | ((child: ReactNode, index?: number) => Record<any, any>);

type AugmentChildren = (
  children: ReactNode,
  addProps?: AddPropsOfAugmentChildren,
  only?: boolean
) => ReactNode;

export const augmentChildren: AugmentChildren = (
  children,
  addProps = {},
  only = false
) => {
  if (only) {
    Children.only(children);
  }

  const augmentedChildren = Children.map(children, (child, idx) =>
    child && (child as ReactElement).props
      ? cloneElement(
          {
            ...(child as ReactElement),
            key: (child as ReactElement).key || `augmented-child-${idx}`,
          },
          isFunction(addProps) ? addProps(child, idx) : addProps
        )
      : child
  );
  return only ? augmentedChildren![0] : augmentedChildren;
};

type AddPropsOfWithAugmentChildren =
  | Record<any, any>
  | ((props: Record<any, any>) => Record<any, any>);
type WrappedComponentType = <P extends object>(
  WrappedComponent: ComponentType<P>
) => FC<any>;
type WithAugmentedChildren = (
  addProps?: AddPropsOfWithAugmentChildren,
  only?: boolean
) => WrappedComponentType;

export const withAugmentedChildren: WithAugmentedChildren =
  (addProps = {}, only = false) =>
  (WrappedComponent) =>
  ({
    children, // eslint-disable-line react/prop-types
    ...props
  }) =>
    (
      <WrappedComponent {...props}>
        {augmentChildren(
          children,
          isFunction(addProps) ? addProps(props) : addProps,
          only
        )}
      </WrappedComponent>
    );
