import PropTypes from 'prop-types';
import React from 'react';
import { Spin } from 'antd';

export default function RowSpinner({ style, height = '36px', ...props }) {
  const defaultStyle = {
    textAlign: 'center',
    height,
    lineHeight: height,
  };
  return (
    <div style={Object.assign(defaultStyle, style)}>
      <Spin {...props} />
    </div>
  );
}

RowSpinner.propTypes = {
  style: PropTypes.object,
  height: PropTypes.any,
};
