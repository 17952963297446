import isNumber from 'lodash/isNumber';
import moment from 'moment';

export default (
  time: string | number | Date,
  format?: string,
  outputFormat?: string
) => {
  let m;
  if (isNumber(time)) {
    // year 2286 in seconds from 1970
    m = time > 10000000000 ? moment(time) : moment.unix(time);
  } else {
    m = moment(time, format);
  }

  if (!time || !m.isValid || String(m) === 'Invalid date') {
    return '-';
  }

  if (outputFormat) {
    return m.format(outputFormat);
  }

  const reference = moment().startOf('day');
  if (m.isAfter(reference)) {
    return m.format('h:mm a');
  }

  return m.format('MMM D, h:mm a');
};
