

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import VerticalGrip from '../../vertical-grip';
import classNames from 'classnames';
import {
  CELL_OVERFLOW_STYLES,
  DEFAULT_CELL_OVERFLOW_STYLE,
} from '../cell-overflow-styles';
import { RowContext } from '../rows/context';
import {
  appTableClsPrefix,
  bodyCellClsPrefix,
  cellClsPrefix,
} from '../cls-prefixes';
import { augmentChildren } from 'src/utils/augment-children';
import { sizeHPadding, sizes } from '../sizes';

const fullHeightContainerCls = `${appTableClsPrefix}__full-height-container`;
const isFullHeightModifierCls = `${bodyCellClsPrefix}--full-height`;

const isFullWidthModifierCls = `${bodyCellClsPrefix}--full-width`;

export default class DefaultTd extends Component {
  static propTypes = {
    overflowStyle: PropTypes.oneOf(Object.values(CELL_OVERFLOW_STYLES)),
    colIndex: PropTypes.number,
    isFullHeight: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    noWrapper: PropTypes.bool,
    isDraggableRow: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.any,
    width: PropTypes.number,
    size: PropTypes.oneOf(Object.values(sizes)),
  };

  static contextType = RowContext;

  state = {
    tdKey: Math.random().toString,
  };

  reRender = () =>
    this.setState({
      tdKey: Math.random().toString,
    });

  render() {
    const {
      overflowStyle = DEFAULT_CELL_OVERFLOW_STYLE,
      noWrapper,
      isFullHeight,
      isFullWidth,
      colIndex,
      isDraggableRow,
      className,
      children,
      width,
      size,
      ...remainingProps
    } = this.props;
    const { rowHeight, reRenderRow } = this.context;
    const { tdKey } = this.state;

    const augmentedChildren = (colIndex === 0 && isDraggableRow
      ? [
          <VerticalGrip
            className={`${bodyCellClsPrefix}__drag-handle`}
            key={`${bodyCellClsPrefix}__drag-handle`}
          />,
        ]
      : []
    ).concat(
      augmentChildren(children, (child) => {
        // Only add props in non DOM components
        return !child.type
          ? {
              rowHeight,
              reRenderRow,
              reRenderCell: this.reRender,
            }
          : {};
      })
    );

    const cellContent = isFullHeight ? (
      <div
        className={fullHeightContainerCls}
        style={
          rowHeight
            ? {
                height: rowHeight,
              }
            : undefined
        }
      >
        {augmentedChildren}
      </div>
    ) : (
      augmentedChildren
    );

    const horizontalPadding = sizeHPadding[size];
    const wrapperWidth = width
      ? `${width - (isFullWidth ? 0 : horizontalPadding) * 2}px`
      : '0px';

    return (
      <td
        key={tdKey}
        className={classNames(
          bodyCellClsPrefix,
          `${cellClsPrefix}--${overflowStyle}`,
          className,
          {
            [isFullHeightModifierCls]: isFullHeight,
            [isFullWidthModifierCls]: isFullWidth,
          }
        )}
        {...remainingProps}
      >
        {noWrapper ? (
          cellContent
        ) : (
          <div
            className={`${cellClsPrefix}__content-wrapper`}
            style={{
              width: wrapperWidth,
            }}
          >
            {cellContent}
          </div>
        )}
      </td>
    );
  }
}
