

import React from 'react';
import { Popover } from 'antd';
import classNames from 'classnames';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import Lazy from 'components/common/lazy';
import getDateMoment from 'src/utils/get-date-moment';

const clsPrefix = 'app-date-popover';

const PopoverContent = (props) => {
  return (
    <div className={`${clsPrefix}__content`}>
      <h4>{props.value.format('MMMM YYYY')}</h4>
      <Lazy
        {...props}
        fullscreen={false}
        component={() => {
          return Promise.all([
            import('antd/lib/calendar'),
            import('antd/lib/calendar/style'),
          ]).then((results) => results[0]);
        }}
      />
    </div>
  );
};

PopoverContent.propTypes = {
  value: PropTypes.object.isRequired,
};

export default function DatePopover({
  className,
  value,
  children,
  popOverProps = {},
  calendarProps = {},
}) {
  if (!value) {
    return children;
  }
  const valueMoment = isString(value) ? getDateMoment(value) : value;

  return (
    <Popover
      {...popOverProps}
      title={null}
      content={<PopoverContent {...calendarProps} value={valueMoment} />}
      overlayClassName={classNames(clsPrefix, className)}
    >
      {children}
    </Popover>
  );
}

DatePopover.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.string.isRequired,
  ]),
  className: PropTypes.string,
  children: PropTypes.any,
  popOverProps: PropTypes.object,
  calendarProps: PropTypes.object,
};
