

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const clsPrefix = 'app-address-cell';

const AddressCell = ({ address }) => {
  let content = '-';
  if (address) {
    const street = `${address.street || ''} ${address.unit || ''}`.trim();
    const city = address.city ? `${address.city},` : '';
    const cityLine = `${city} ${address.state || ''} ${address.zipCode ||
      ''}`.trim();

    content = (
      <Fragment>
        {street && <span className={`${clsPrefix}__street`}>{street}</span>}
        {city && <span className={`${clsPrefix}__city`}>{cityLine}</span>}
      </Fragment>
    );
  }

  return <div className={clsPrefix}>{content}</div>;
};

AddressCell.propTypes = {
  address: PropTypes.object,
};

export default AddressCell;
