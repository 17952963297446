

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import formatFullTimestamp from 'src/utils/format-full-timestamp';
import { Tooltip } from 'antd';

const clsPrefix = 'app-ts-cell';

const MODES = ['default', 'dark'];
const SIZES = ['small', 'medium', 'large'];

const TsCell = ({ className, ts, format, title, mode, size, style }) => (
  <Tooltip title={title}>
    <span
      className={classNames(
        clsPrefix,
        `${clsPrefix}--${mode}`,
        `${clsPrefix}--${size}`,
        className
      )}
      style={style || {}}
    >
      {formatFullTimestamp(+ts, undefined, format)}
    </span>
  </Tooltip>
);

TsCell.propTypes = {
  className: PropTypes.string,
  ts: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  format: PropTypes.string,
  title: PropTypes.any,
  mode: PropTypes.oneOf(MODES),
  size: PropTypes.oneOf(SIZES),
  style: PropTypes.object,
};
TsCell.defaultProps = {
  mode: 'default',
  size: 'medium',
};

export default TsCell;
